:root {

  /**
     * colors
     */

  --chinese-violet_30: hsla(304, 14%, 46%, 0.3);
  --chinese-violet: hsl(304, 14%, 46%);
  --sonic-silver: hsl(208, 7%, 46%);
  --old-rose_30: hsla(357, 37%, 62%, 0.3);
  --ghost-white: hsl(233, 33%, 95%);
  --light-pink: hsl(357, 93%, 84%);
  --light-gray: hsl(0, 0%, 80%);
  --old-rose: hsl(357, 37%, 62%);
  --seashell: hsl(20, 43%, 93%);
  --charcoal: hsl(203, 30%, 26%);
  --white: hsl(0, 0%, 100%);

  /**
     * typography
     */

  --ff-philosopher: 'Philosopher', sans-serif;
  --ff-poppins: 'Poppins', sans-serif;

  --fs-1: 4rem;
  --fs-2: 3.2rem;
  --fs-3: 2.7rem;
  --fs-4: 2.4rem;
  --fs-5: 2.2rem;
  --fs-6: 2rem;
  --fs-7: 1.8rem;

  --fw-500: 500;
  --fw-700: 700;

  /**
     * spacing
     */

  --section-padding: 80px;

  /**
     * shadow
     */

  --shadow-1: 4px 6px 10px hsla(231, 94%, 7%, 0.06);
  --shadow-2: 2px 0 15px 5px hsla(231, 94%, 7%, 0.06);
  --shadow-3: 3px 3px var(--chinese-violet);
  --shadow-4: 5px 5px var(--chinese-violet);

  /**
     * radius
     */

  --radius-5: 5px;
  --radius-10: 10px;

  /**
     * clip path
     */

  --polygon: polygon(100% 29%, 100% 100%, 19% 99%, 0 56%);

  /**
     * transition
     */

  --transition-1: 0.25s ease;
  --transition-2: 0.5s ease;
  --cubic-out: cubic-bezier(0.33, 0.85, 0.4, 0.96);

}








/*-----------------------------------*\
    #CHAPTERS
  \*-----------------------------------*/



.about-next-card {
  height: 100%;
  background-color: #e4c590;
  color: black;
  padding: 25px;
  float: left;
  border-radius: var(--radius-5);
  box-shadow: var(--shadow-2);
  transition: var(--transition-2);
  width: 29%;
  margin: 2%;
}

.about-next-card:is(:hover, :focus-within) {
  transform: translateY(-10px);
}

.about-next-card .card-title {
  margin-block-end: 15px;
}


.card-text {
  color: black !important;
}

.card-subtitle {
  color: white !important;
}




/*-----------------------------------*\
  #DONATE
\*-----------------------------------*/

.donate-list {
  display: grid;
  height: 35vh;
}

.donate-card {
  box-shadow: var(--card-shadow);
  opacity: 0.8;
  justify-content: center;
  width: 80%;
  margin: 50px 10px;
}

.donate-card .card-banner {
  background-color: var(--light-gray);
}

.donate-card .card-content {
  background-color: var(--white);
  padding: 15px;
}

.mission {
  background-image: url("../image/mission.jpeg");
  background-size: 100%;
  background-repeat: no-repeat;
}

.vision {
  background-image: url("../image/vision.jpeg");
  background-size: 100%;
  background-repeat: no-repeat;
}

.industry-bullet{
  display: inline-block;
  margin: 1%;
  color: #1e1711 !important;
  text-align: justify;
}
.industry-icon{
  float: left !important;
  margin-right: 2%;
  width: 16px !important;
  font-size: 16px !important;
}

.donate-card .card-title {
  --fs-5: 2.1rem;
}



.card-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
}

.donate-card .card-wrapper-text {
  display: flex;
  gap: 5px;
}

.donate-card .card-wrapper-text span {
  color: var(--sonic-silver);
  font-weight: var(--fw-500);
}

.card-wrapper-text data {
  font-weight: var(--fw-600);
}

.card-wrapper-text .green {
  color: var(--pistachio);
}

.card-wrapper-text .yellow {
  color: var(--bright-yellow-crayola);
}

.card-wrapper-text .cyan {
  color: var(--medium-turquoise);
}

/*-----------------------------------*\
  #AUTHOR
\*-----------------------------------*/


.author .container {
  display: grid;
  gap: 40px;
}

.author :is(.section-subtitle, .section-title) {
  text-align: left;
}

.author-content {
  background-color: var(--white);
  padding: 30px;
}

.author-name {
  font-size: var(--fs-6);
  margin-block-end: 30px;
}

.author .section-text {
  font-size: unset;
}

.industry-card-title{
  font-size: 25px;
  justify-content: center;
  margin: 2%;
  text-align: center;
}

.industry-cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  grid-template-rows: 1fr 1fr;
}

.industry-card {
  background-color: #e4c590;
  color: black;
  padding: 4%;
  float: left;
  width: 45%;
  margin: 2%;
}

/*-----------------------------------*\
    #MEDIA QUERIES
  \*-----------------------------------*/



@media (min-width: 575px) {

  /**
     * CUSTOM PROPERTY
     */

  :root {

    /**
       * typography
       */

    --fs-2: 4.5rem;
    --fs-3: 3.5rem;
    --fs-5: 2.5rem;

  }

  /**
   * DONATE
   */

  .donate-card {
    position: relative;
    padding-block-end: 150px;
    box-shadow: none;
  }

  .donate-card .card-content {
    position: absolute;
    width: 85%;
    bottom: 0;
    right: 0;
    border-radius: 4px;
    box-shadow: var(--card-shadow);
  }

  .donate-card .card-title {
    --fs-5: 2.4rem;
  }





}





/**
   * responsive for large than 768px screen
   */

@media (min-width: 768px) {

  /**
     * CUSTOM PROPERTY
     */

  :root {

    /**
       * typography
       */

    --fs-2: 4.8rem;

  }


  /**
   * AUTHOR
   */

  .author .h2 {
    --fs-3: 4.2rem;
  }




}





/**
   * responsive for large than 992px screen
   */

@media (min-width: 992px) {

  /**
     * CUSTOM PROPERTY
     */

  :root {

    /**
       * typography
       */

    --fs-2: 5.4rem;
    --fs-3: 4rem;

  }


  /**
   * DONATE
   */

  .donate-list {
    grid-template-columns: 1fr 1fr;
    column-gap: 30px;
  }

  /**
   * AUTHOR
   */

  .author .container {
    grid-template-columns: 0.6fr 1fr;
    gap: 0;
  }

  .author-content {
    margin-block-start: 40px;
    margin-inline-start: -30px;
    padding-block-end: 70px;
  }


}





/**
   * responsive for large than 1200px screen
   */

@media (min-width: 1200px) {

  /**
     * CUSTOM PROPERTY
     */

  :root {

    /**
       * typography
       */

    --fs-2: 6rem;
    --fs-3: 4.5rem;
    --fs-4: 3rem;
    --fs-5: 3rem;

  }

  /**
   * DONATE
   */

  .donate-card {
    padding-inline-end: 120px;
  }

  .donate-card .card-content {
    width: 65%;
  }


  /**
   * AUTHOR
   */

  .author-content {
    padding: 85px 60px 115px;
  }


}