* {
    box-sizing: border-box;
  }
  
.gallery {
    margin-top: 20px;
    margin-left: 5px;
    margin-right: 5px;
    font-family: Arial;
    height: 285vh !important;
    overflow:hidden;
    background-color: #1E1711;
  }

  
  
  .section-title {
    text-align: center;
    padding: 32px;
  }
  
  .row {
    display: -ms-flexbox; /* IE10 */
    display: flex;
    -ms-flex-wrap: wrap; /* IE10 */
    flex-wrap: wrap;
    padding: 0 4px;
  }
  
  /* Create four equal columns that sits next to each other */
  .column {
    -ms-flex: 33.3%; /* IE10 */
    flex: 33.3%;
    max-width: 33.3%;
    padding: 0 4px;
  }
  
  .column img {
    margin-top: 8px;
    vertical-align: middle;
    width: 100%;
  }
  
  /* Responsive layout - makes a two column-layout instead of four columns */
  @media screen and (max-width: 800px) {
    .column {
      -ms-flex: 50%;
      flex: 50%;
      max-width: 50%;
    }
  }
  
  /* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
  @media screen and (max-width: 600px) {
    .column {
      -ms-flex: 100%;
      flex: 100%;
      max-width: 100%;
    }
  }