/*-----------------------------------*\
  #EVENT
\*-----------------------------------*/

.event {
    background-color: #1e1711;
}



.event .section-title {
    margin-block-end: 40px;
}

.event-card {
    position: relative;
    overflow: hidden;
    
    height: 50vh !important;
}

.event-card .card-content {
    background-image: var(--gradient-1);
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 35px 35px 25px;
}

.event-card .publish-date {
    position: absolute;
    top: 30px;
    left: 25px;
    padding: 5px 10px;
    color: var(--gold-crayola);
    background-color: var(--black);
    font-weight: var(--weight-bold);
    letter-spacing: var(--letterSpacing-1);
    line-height: 14px;
}

.event-card .card-subtitle {
    color: var(--gold-crayola);
    text-transform: uppercase;
    font-weight: var(--weight-bold);
    letter-spacing: var(--letterSpacing-2);
    margin-block-end: 5px;
}

.img-cover {
    height: 30vh !important;
}

.video_button {
    background-color: #AA9563;
    width: 100%;
    padding: 2% 5%;
    justify-content: center;
    color: white;
    border: 1px solid white;

}

.video_button:hover {
    background-color: #1e1711;
    color: white;
    border: 1px solid #1e1711;
}

.event-card .card-banner .img-cover {
    transition: var(--transition-2);
}

.event-card:is(:hover, :focus-within) .img-cover {
    transform: scale(1.05);
}

.event .btn {
    margin-inline: auto;
    margin-block-start: 40px;
}