:root {

    /**
     * colors
     */

    --russian-violet: hsl(269, 36%, 22%);
    --dark-purple: hsl(268, 22%, 15%);
    --eerie-black: hsl(240, 4%, 10%);
    --davys-gray: hsl(0, 0%, 34%);
    --light-gray: hsl(0, 0%, 84%);
    --cultured-1: hsl(0, 0%, 98%);
    --cultured-2: hsl(280, 14%, 96%);
    --cultured-3: hsl(280, 9%, 94%);
    --gainsboro: hsl(0, 1%, 85%);
    --black_10: hsla(0, 0%, 0%, 0.1);
    --black_5: hsla(0, 0%, 0%, 0.05);
    --pumpkin: white;
    --white: hsl(0, 0%, 100%);
    --black: hsl(0, 0%, 0%);

    /**
     * typography
     */

    --ff-poppins: 'Poppins', sans-serif;
    --ff-roboto: 'Roboto', sans-serif;

    --fs-1: 3rem;
    --fs-2: 2rem;
    --fs-3: 1.5rem;
    --fs-4: 1.4rem;
    --fs-5: 1.3rem;

    --fw-900: 900;
    --fw-700: 700;
    --fw-600: 600;
    --fw-500: 500;

    /**
     * spacing
     */

    --section-padding: 50px;

    /**
     * box shadow
     */

    --shadow-1: 0px 0px 20px var(--black_10);
    --shadow-2: 0 0 20px 4px var(--black_5);

    /**
     * border radius
     */

    --radius-6: 6px;
    --radius-10: 10px;

    /**
     * transition
     */

    --transition-1: 0.25s ease;
    --transition-2: 0.5s ease;
    --cubic-in: cubic-bezier(0.51, 0.03, 0.64, 0.28);
    --cubic-out: cubic-bezier(0.33, 0.85, 0.4, 0.96);

}





/*-----------------------------------*\
    #RESET
  \*-----------------------------------*/

*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

li {
    list-style: none;
}

a {
    text-decoration: none;
    color: inherit;
}

a,
img,
span,
button,
ion-icon {
    display: block;
}

img {
    height: auto;
}



ion-icon {
    pointer-events: none;
}

address {
    font-style: normal;
}



:focus-visible {
    outline-offset: 4px;
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background-color: hsl(0, 0%, 98%);
}

::-webkit-scrollbar-thumb {
    background-color: hsl(0, 0%, 80%);
}

::-webkit-scrollbar-thumb:hover {
    background-color: hsl(0, 0%, 70%);
}



/*-----------------------------------*\
    #video
  \*-----------------------------------*/

.video .section-title {
    margin-block-end: 30px;
}

.video-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    gap: 30px;
    grid-template-columns: 1fr 1fr 1fr;
}

.video-list>li {
    max-width: 100%;
}

.video-card {
    padding: 15px;
    border-radius: var(--radius-10);
    box-shadow: var(--shadow-2);
    float:'left';
    justify-content: left;
}

.video-card .card-banner {
    border-radius: var(--radius-10);

}

.player{
    width: 100% !important;
}

.video-card:is(:hover, :focus-within) .img-cover {
    transform: scale(1.2);
}

.video-card .card-content {
    padding: 15px;
    padding-block-start: 25px;
}

.video-card :is(.card-meta-list, .card-meta-item) {
    display: flex;
    align-items: center;
}

.video-card .card-meta-list {
    gap: 15px;
    margin-block-end: 15px;
}

.video-card .card-meta-item {
    gap: 6px;
}

.video-card .card-meta-item ion-icon {
    color: var(--pumpkin);
    font-size: var(--fs-5);
}

.video-card .card-meta-text {
    font-size: var(--fs-5);
}

.video-card .h3 {
    margin-block-end: 15px;
}

.video-card .card-title {
    transition: var(--transition-1);
}

.video-card .card-title:is(:hover, :focus) {
    color: #AA9563;
}

.video_button {
    background-color: #AA9563;
    width: 100%;
    padding: 2% 5%;
    justify-content: center;
    color: white;
    border: 1px solid #AA9563;

}

.video_button:hover {
    background-color: #1e1711;
    color: white;
    border: 1px solid white;
}


/*-----------------------------------*\
    #MEDIA QUERIES
  \*-----------------------------------*/

/**
   * responsive for larger than 575px screen
   */

@media(min-width: 575px) {

    /**
     * REUSED STYLE
     */

    .container {
        max-width: 540px;
        width: 100%;
        margin-inline: auto;
    }




}





/**
   * responsive for larger than 768px screen
   */

@media (min-width: 768px) {

    /**
     * CUSTOM PROPERTY
     */

    :root {

        /**
       * typography
       */

        --fs-1: 4.5rem;

    }



    /**
     * REUSED STYLE
     */

    .container {
        max-width: 720px;
    }

    .h2 {
        --fs-1: 3.5rem;
    }

    





    /**
     * video
     */

    .video-list {
        gap: 20px;
    }

    .video-list>li {
        max-width: calc(50% - 10px);
    }

}





/**
   * responsive for larger than 992px screen
   */

@media (min-width: 992px) {

    /**
     * CUSTOM PROPERTY
     */

    :root {

        /**
       * spacing
       */

        --section-padding: 100px;

    }



    /**
     * REUSED STYLE
     */

    .container {
        max-width: 960px;
    }

    



    /**
     * video
     */

    .video-list>li {
        max-width: calc(33.33% - 13.33px);
    }



    /**
     * FOOTER
     */

    .footer-top .container {
        grid-template-columns: 1fr 0.5fr 0.5fr 0.5fr;
        gap: 50px;
    }

}





/**
   * responsive for larger than 1200px screen
   */

@media (min-width: 1200px) {

    /**
     * CUSTOM PROPERTY
     */

    :root {

        /**
       * typography
       */

        --fs-1: 5rem;

    }



    /**
     * REUSED STYLE
     */

    .container {
        max-width: 1180px;
    }

    .section-text {
        --fs-4: 1.5rem;
    }

    .h2 {
        --fs-1: 4rem;
    }

    .h3 {
        --fs-2: 2.2rem;
    }




    /**
     * video
     */

    .video-list {
        gap: 25px;
    }

    .video-list>li {
        max-width: calc(33.33% - 16.66px);
    }



}