/*-----------------------------------*\
  #style.css
\*-----------------------------------*/

/**
 * copyright 2022 codewithsadee
 */





/*-----------------------------------*\
  #CUSTOM PROPERTY
\*-----------------------------------*/

:root {

  /**
   * colors
   */

  --bright-yellow-crayola: hsl(40, 100%, 50%);
  --medium-turquoise: hsl(172, 48%, 59%);
  --silver-chalice: hsl(0, 0%, 70%);
  --eerie-black-1: hsl(0, 0%, 13%);
  --eerie-black-2: hsl(0, 0%, 15%);
  --eerie-black-3: hsl(228, 10%, 10%);
  --raisin-black: hsl(220, 8%, 14%);
  --granite-gray: hsl(0, 0%, 38%);
  --sonic-silver: hsl(0, 0%, 47%);
  --pistachio_50: hsla(86, 45%, 54%, 0.5);
  --baby-powder: hsl(86, 41%, 97%);
  --light-gray: hsl(0, 0%, 80%);
  --pistachio: hsl(86, 45%, 54%);
  --platinum: hsl(0, 0%, 91%);
  --cultured: hsl(0, 0%, 94%);
  --white-60: hsla(0, 0%, 100%, 0.6);
  --white_50: hsla(0, 0%, 100%, 0.5);
  --white_10: hsla(0, 0%, 100%, 0.1);
  --white: hsl(0, 0%, 100%);
  --black: hsl(0, 0%, 0%);
  --jet: hsl(0, 0%, 18%);

  /**
   * typography
   */

  --ff-oswald: 'Oswald', sans-serif;
  --ff-inter: 'Inter', sans-serif;
  --ff-roboto: 'Roboto', sans-serif;

  --fs-1: 13rem;
  --fs-2: 4rem;
  --fs-3: 3.5rem;
  --fs-4: 3rem;
  --fs-5: 2.4rem;
  --fs-6: 2.2rem;
  --fs-7: 2rem;
  --fs-8: 1.8rem;
  --fs-9: 1.5rem;
  --fs-10: 1.4rem;

  --fw-300: 300;
  --fw-500: 500;
  --fw-600: 600;
  --fw-700: 700;

  /**
   * spacing
   */

  --section-padding: 80px;

  /**
   * shadow
   */

  --card-shadow: 0 5px 20px -8px hsla(0, 0%, 0%, 0.15);

  /**
   * transition
   */

  --transition-1: 0.05s ease;
  --transition-2: 0.25s ease;
  --cubic-in: cubic-bezier(0.51, 0.03, 0.64, 0.28);
  --cubic-out: cubic-bezier(0.33, 0.85, 0.4, 0.96);

}





/*-----------------------------------*\
  #RESET
\*-----------------------------------*/

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

a,
img,
span,
time,
input,
strong,
button,
ion-icon {
  display: block;
}

img {
  height: auto;
}

ion-icon {
  pointer-events: none;
}

input,
button {
  background: none;
  border: none;
  font: inherit;
}

input {
  width: 100%;
}

button {
  cursor: pointer;
}

:focus-visible {
  outline-offset: 4px;
}

::selection {
  background-color: var(--white_10);
}



::-webkit-scrollbar {
  width: 13px;
}

::-webkit-scrollbar-track {
  background-color: hsl(0, 0%, 95%);
}







/*-----------------------------------*\
  #REUSED STYLE
\*-----------------------------------*/

.container {
  padding-inline: 15px;
}


.h1,
.h2 {
  font-weight: var(--fw-300);
}

.h2,
.h3 {
  line-height: 1.6;
}

.h2 {
  font-size: var(--fs-3);
  color: var(--eerie-black-2);
}

.h3 {
  font-size: var(--fs-5);
  color: var(--eerie-black-2);
}

.h4 {
  color: var(--white);
  font-size: var(--fs-6);
}





.section {
  padding-block: var(--section-padding);
}

.w-100 {
  width: 100%;
}

.section-text {
  line-height: 2;
}

.img-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}




/*-----------------------------------*\
  #TESTIMONIALS
\*-----------------------------------*/

.contact {
  background-color: #5b1708;
}

.contact-content {
  padding: var(--section-padding) 15px;
}

.contact .section-title {
  color: var(--white);
  margin-block: 10px 40px;
}

.contact-card {
  color: var(--white);
}

.contact-text,
.contact-name {
  font-size: var(--fs-8);
}

.contact-text,
.contact-title {
  font-family: var(--ff-inter);
}

.contact-text {
  padding-block-end: 30px;
  border-block-end: 1px solid var(--white_10);
  margin-block: 20px 30px;
}

.contact-title {
  color: var(--white_50);
  font-size: var(--fs-10);
}

.contact-banner {
  background-color: var(--light-gray);
}

.message {
  background-color: white;
  width: 100%;
  padding: 3%;
  font-size: 16px;
  font-style: bold;
  margin-bottom: 3%;
  border: 1px solid #3c3c3c;
}

input[type="text"] {

  background-color : white; 
  color: #1e1711;
}
input[type="email"] {

  background-color : white;
  color: #1e1711;

}

textarea::-webkit-input-placeholder {
  color: #1e1711;
}

::-webkit-input-placeholder {
  color: #1e1711;
}



/*-----------------------------------*\
  #MEDIA QUERIES
\*-----------------------------------*/

/**
 * responsive for larger than 450px screen
 */

@media (min-width: 450px) {

  /**
   * CUSTOM PROPERTY
   */

  :root {

    /**
     * typography
     */

    --fs-3: 4rem;

  }




}





/**
 * responsive for larger than 580px screen
 */

@media (min-width: 580px) {

  /**
   * CUSTOM PROPERTY
   */

  :root {

    /**
     * typography
     */

    --fs-2: 5.5rem;

  }



}





/**
 * responsive for larger than 768px screen
 */

@media (min-width: 768px) {

  /**
   * TESTIMONIALS
   */

  .contact-card {
    display: flex;
    align-items: flex-start;
    gap: 30px;
  }

  .contact-text {
    margin-block-start: 0;
  }

}





/**
 * responsive for larger than 992px screen
 */

@media (min-width: 992px) {

  /**
   * CUSTOM PROPERTY
   */

  :root {

    /**
     * typography
     */

    --fs-2: 7.5rem;

  }




  /**
   * TESTIMONIALS
   */

  .contact {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .contact-content {
    padding-inline: 30px 50px;
  }





}





/**
 * responsive for larger than 1200px screen
 */

@media (min-width: 1200px) {

  /**
   * CUSTOM PROPERTY
   */

  :root {

    /**
     * typography
     */

    --fs-3: 4.4rem;

  }



  /**
   * REUSED STYLE
   */

  .container {
    max-width: 1400px;
    width: 100%;
    margin-inline: auto;
    padding-inline: 30px;
  }

  .section-title {
    line-height: 1.2;
  }



}