/*-----------------------------------*\
  #style.css
\*-----------------------------------*/


:root {

  /**
     * colors
     */

  --russian-violet: hsl(269, 36%, 22%);
  --dark-purple: hsl(268, 22%, 15%);
  --eerie-black: hsl(240, 4%, 10%);
  --davys-gray: hsl(0, 0%, 34%);
  --light-gray: hsl(0, 0%, 84%);
  --cultured-1: hsl(0, 0%, 98%);
  --cultured-2: hsl(280, 14%, 96%);
  --cultured-3: hsl(280, 9%, 94%);
  --gainsboro: hsl(0, 1%, 85%);
  --black_10: hsla(0, 0%, 0%, 0.1);
  --black_5: hsla(0, 0%, 0%, 0.05);
  --pumpkin: hsl(22, 90%, 56%);
  --white: hsl(0, 0%, 100%);
  --black: hsl(0, 0%, 0%);

  /**
     * typography
     */

  --ff-poppins: 'Poppins', sans-serif;
  --ff-roboto: 'Roboto', sans-serif;

  --fs-1: 3rem;
  --fs-2: 2rem;
  --fs-3: 1.5rem;
  --fs-4: 1.4rem;
  --fs-5: 1.3rem;

  --fw-900: 900;
  --fw-700: 700;
  --fw-600: 600;
  --fw-500: 500;

  /**
     * spacing
     */

  --section-padding: 50px;

  /**
     * box shadow
     */

  --shadow-1: 0px 0px 20px var(--black_10);
  --shadow-2: 0 0 20px 4px var(--black_5);

  /**
     * border radius
     */

  --radius-6: 6px;
  --radius-10: 10px;

  /**
     * transition
     */

  --transition-1: 0.25s ease;
  --transition-2: 0.5s ease;
  --cubic-in: cubic-bezier(0.51, 0.03, 0.64, 0.28);
  --cubic-out: cubic-bezier(0.33, 0.85, 0.4, 0.96);

}





/*-----------------------------------*\
    #RESET
  \*-----------------------------------*/

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

a,
img,
span,
button,
ion-icon {
  display: block;
}

img {
  height: auto;
}

button {
  background: none;
  border: none;
  font: inherit;
  cursor: pointer;
}

ion-icon {
  pointer-events: none;
}

address {
  font-style: normal;
}

html {
  font-family: var(--ff-poppins);
  font-size: 10px;
  scroll-behavior: smooth;
}

body {
  background-color: #1e1711;
  font-size: 1.6rem;
  color: white;
}









/*-----------------------------------*\
    #FOOTER
  \*-----------------------------------*/

.footer {
  background-color: #212121;
}

.footer-top .container {
  display: grid;
  gap: 30px;
}

.footer-top .section-text {
  margin-block: 15px 22px;
}

.social-list {
  display: flex;
  gap: 15px;
}

.social-link {
  background-color: var(--white);
  padding: 12px;
  border-radius: 50%;
  transition: var(--transition-1);
}

.social-link:is(:hover, :focus) {
  background-color: var(--pumpkin);
  color: var(--white);
  transform: translateY(-2px);
}

.footer-list-title {
  color: white;
  font-family: var(--ff-roboto);
  font-size: var(--fs-2);
  font-weight: var(--fw-700);
  margin-block-end: 20px;
}

.footer-link {
  font-size: var(--fs-4);
  margin-block-start: 10px;
  transition: var(--transition-1);
}

.footer-link:is(:hover, :focus),
.footer-item .item-link:not(.address):is(:hover, :focus) {
  color: var(--pumpkin);
}

.footer-item {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  margin-block-start: 10px;
}

.footer-item ion-icon {
  flex-shrink: 0;
  color: var(--pumpkin);
  font-size: 17px;
  margin-block-start: 2px;
}

.footer-item .item-link {
  font-size: var(--fs-4);
  transition: var(--transition-1);
}

.footer-bottom {
  padding-block: 20px;
  border-block-start: 1px solid var(--gainsboro);
}

.copyright {
  font-size: var(--fs-4);
  text-align: center;
}

.copyright-link {
  color: var(--pumpkin);
  display: inline-block;
}





/*-----------------------------------*\
    #BACK TO TOP
  \*-----------------------------------*/

/* .back-top-btn {
    position: fixed;
    bottom: 30px;
    right: -36px;
    background-color: var(--pumpkin);
    color: var(--white);
    padding: 10px;
    border-radius: 50%;
    opacity: 0;
    visibility: hidden;
    transition: var(--transition-1);
  }
  
  .back-top-btn.active {
    opacity: 1;
    visibility: visible;
    transform: translateX(-51px);
  }
   */






/*-----------------------------------*\
    #MEDIA QUERIES
  \*-----------------------------------*/

/**
   * responsive for larger than 575px screen
   */

@media (min-width: 575px) {

  /**
     * REUSED STYLE
     */

  .container {
    max-width: 540px;
    width: 100%;
    margin-inline: auto;
  }


  /**
     * FOOTER
     */

  .footer-top .container {
    grid-template-columns: 1fr 1fr;
  }

}





/**
   * responsive for larger than 768px screen
   */

@media (min-width: 768px) {

  /**
     * CUSTOM PROPERTY
     */

  :root {

    /**
       * typography
       */

    --fs-1: 4.5rem;

  }



  /**
     * REUSED STYLE
     */

  .container {
    max-width: 720px;
  }

  .h2 {
    --fs-1: 3.5rem;
  }

  .btn {
    font-size: unset;
    padding: 15px 30px;
  }

  .btn-list {
    gap: 20px;
  }

  .btn-item-link .img {
    width: 150px;
  }



}





/**
   * responsive for larger than 992px screen
   */

@media (min-width: 992px) {

  /**
     * CUSTOM PROPERTY
     */

  :root {

    /**
       * spacing
       */

    --section-padding: 100px;

  }



  /**
     * REUSED STYLE
     */

  .container {
    max-width: 960px;
  }

  .btn-item-link .img {
    width: 185px;
  }


  /**
     * FOOTER
     */

  .footer-top .container {
    grid-template-columns: 1fr 0.5fr 0.5fr 0.5fr;
    gap: 50px;
  }

}





/**
   * responsive for larger than 1200px screen
   */

@media (min-width: 1200px) {

  /**
     * CUSTOM PROPERTY
     */

  :root {

    /**
       * typography
       */

    --fs-1: 5rem;

  }



  /**
     * REUSED STYLE
     */

  .container {
    max-width: 1180px;
  }

  .section-text {
    --fs-4: 1.5rem;
  }

  .h2 {
    --fs-1: 4rem;
  }

  .h3 {
    --fs-2: 2.2rem;
  }



  /**
     * FOOTER
     */

  .footer-list-title {
    --fs-2: 2.2rem;
  }

  .social-link {
    font-size: 18px;
    padding: 14px;
  }

  .footer-link,
  .copyright {
    --fs-4: 1.5rem;
  }



  /**
     * BACK TO TOP
     */

  .back-top-btn {
    font-size: 24px;
    padding: 13px;
    bottom: 40px;
  }

  .back-top-btn.active {
    transform: translateX(-80px);
  }

}