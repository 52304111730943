.markup{
    text-align:center;
    color: white !important;

}

.markup > p{
    color: white !important;
}

.news-img{
    width: 20% !important;
    justify-content: center;
}